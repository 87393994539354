import { template as template_11423201fece42a3ac17c38dd4454ed2 } from "@ember/template-compiler";
const WelcomeHeader = template_11423201fece42a3ac17c38dd4454ed2(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
