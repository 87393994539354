import { template as template_acec4c948fd94c0a886ec5826be55d08 } from "@ember/template-compiler";
const FKControlMenuContainer = template_acec4c948fd94c0a886ec5826be55d08(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
